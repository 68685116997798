<template>
  <div class="w80-crpwd my-20">
    <div class="row text-center">
      <div class="col-lg-6 col-md-6 col-sm-12 d-none d-md-block">
        <img
          class="liammaxmobil"
          src="../../../assets/2025/pilotos.png"
          alt="YukiMaxRB"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="formResetPassword mt-md-10 mt-ms-1">
          <!-- <img
            class="headregister d-sm-none mb-10"
            src="../../../assets/registerHead.png"
            alt="Mobile Logo"
          /> -->
          <h1 class="font-white font-bold text-center">
            Buscar cuenta de correo
          </h1>
          <p class="font-white font-regular text-center resetpwd-text">
            Te enviaremos un correo electrónico para recuperar tu cuenta. Ingresa el correo con el que te registraste aquí:
          </p>
          <form
            action=""
            class="mt-5 w100"
            @submit.prevent="submitFormRegister"
          >
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24 resetpwd-input"
                id="email"
                v-model="form.email"
                placeholder="Correo*"
              />
            </div>
            <div class="d-flex justify-center mt-10">
              <button class="btn btn-primary btn-lg btn-launch-crpwd">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./resetPassword.js" />
<style src="./resetPassword.scss" lang="scss" />
<style src="../../../App.scss" lang="scss"/>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'resetPassword',
  metaInfo: {
    title: 'Olvidé mi contraseña - Mobil Predictor',
    meta: [
      { name: 'title', content: 'Olvidé mi contraseña - Mobil Predictor' },
      { name: 'description', content: '¿Olvidaste tu contraseña de Mobil Predcitor? Encuentra tu dirección y reestablece tu clave. Te enviaremos un correo para recuperar tu cuenta. Ingresa aquí.' }
    ]
  },
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  methods: {
    async submitFormRegister() {
      try {
        axios.defaults.headers.common["Content-Type"] = "application/json"; // Add this line
        const response = await axios.post(
          'https://www.mobilpredictor.com.mx/datos/api/password/reset',
          // 'http://127.0.0.1:8000/api/password/reset',
          // 'https://api.mobilpredictor2025.vmtec.com.mx/api/password/reset',
          this.form
        );

        if (response.data.message == "Success") {
          Swal.fire({
            icon: "success",
            title: "Correo enviado",
            text: "Hemos enviado las instrucciones al correo que nos proporcionaste.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
                color: "#fff",
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 1500);
        }
      } catch (error) {
        // console.error(error);
        if (error.response.message == "AccountNotFound") {
          Swal.fire({
            icon: "warning",
            title: "Correo no registrado",
            text: "Por favor verifica tu correo e intenta nuevamente",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
                color: "#fff",
          });
        }
      }
    },
  },
};
</script>
