<template>
  <div class="w100 px-5 px-sm-10 py-5">
    <div class="row mx-md-10">
      <div class="col-lg-1 col-md-1"></div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 container-text-home">
        <p class="font-white font-regular text-home">Bienvenido a Mobil Predictor, una
          experiencia creada por Mobil para que
          vivas cada carrera como nunca antes.
          Gracias a nuestra alianza tecnológica
          con Oracle Red Bull Racing, llevamos la
          emoción de la Fórmula 1 a otro nivel.
          Predice los resultados, súbele a la
          adrenalina y disfruta al máximo cada
          Gran Carrera.</p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <img class="image-home" src="../../../assets/2025/home_pilotos.png" alt="MaxRedBull2025" />
      </div>
      <div class="col-lg-1 col-md-1"></div>
    </div>
    <!-- <div class="mt-3 boton-empezar-mobile">
      <a href="/iniciar-sesion" class="home-button">
        Empezar
      </a>
    </div> -->
    <!-- <div class="px-md-10 d-flex align-stretch justify-end">
      <p class="font-white text-home-brand italica">Powered by Mobil<sup>®</sup> Racing Club</p>
    </div> -->
  </div>
</template>

<script src="./home.js" />
<style src="./home.scss" lang="scss" />
<script>
export default {
  mounted() {
    fbq('track', 'Traffic');
  },
}
</script>
