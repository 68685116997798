<template>
  <div class="w80-crpwd my-10">
    <div class="row">
      <div class="col-lg-1 col-md-1 d-none d-md-block d-sm-none"></div>
      <div class="col-lg-2 col-md-2 col-sm-1 d-none d-md-block d-sm-none">
        <div class="prediccionesCheco-container-texto">
          <h1 class="font-white font-regular prediccionesCheco-titulo italic">
            Predicciones
          </h1>
          <p class="font-white font-regular prediccionesCheco-texto">
            Es tu turno de pisar el acelerador. Añade tus avances y pronósticos para la próxima Gran Carrera.
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-1 d-none d-lg-block d-sm-none text-center">
        <img class="prediccionesLiam" src="../../../../../../src/assets/2025/Yuki.png" alt="Yuki Tsunoda" />
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <div class="contenedor-predicciones text-center py-5">
          <!-- <h1 class="font-white f40 mt-5">{{name_race}}</h1>
          <p class="font-white font-regular">{{circuito}}</p>
          <p class="font-white font-regular">{{ciudad}}</p> -->
          <img class="mt-3 mb-3 d-sm-none checo_dm" src="../../../../../assets/2025/Yuki_predicciones.png" alt="Yuki Tsunoda" />
          <div class="container-botones-predicciones">
            <div class="">
              <button @click="alertaCambioPaginaCheco" class="piloto2 font-white font-regular font-regular italic">
                Max
              </button>
            </div>
            <div class="boton-secundario">
              <a href="/predicciones-pilotos-yuki" class="piloto font-white page-predicciones italic">Yuki</a>
            </div>
            <div class="boton-secundario">
              <button @click="alertaCambioPaginaMobil" class="piloto2 font-white font-regular font-regular italic">
                Mobil
              </button>
            </div>
          </div>
          <div class="predicciones">
            <p class="font-white f40 mt-5" v-if="!showQuestions">
              Predicciones no disponibles, se abren el lunes antes de la carrera.
            </p>
            <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="showQuestions && !isFormVisible">
              <!-- Pregunta 1 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[0].pregunta1 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[0].respuesta1">
                  <option disabled value="">Respuesta</option>
                  <option v-for="num in 20" :value="num" :key="num">{{ num }}</option>
                  <option value="DNF">DNF</option>
                </select>
                <p class="font-white font-regular score mt-3">100 Puntos</p>
              </div>
              <!-- Fin de pregunta 1 -->
              <!-- Pregunta 2 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[1].pregunta2 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[1].respuesta2">
                  <option disabled value="">Respuesta</option>
                  <option v-for="num in 20" :value="num" :key="num">{{ num }}</option>
                </select>
                <p class="font-white font-regular score mt-3">100 Puntos</p>
              </div>
              <!-- Fin de pregunta 2 -->
              <!-- Pregunta 3 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[2].pregunta3 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[2].respuesta3">
                  <option disabled value="">Respuesta</option>
                  <option v-for="num in 20" :value="num" :key="num">{{ num }}</option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- Fin de pregunta 3 -->
              <!-- Pregunta 4 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[3].pregunta4 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[3].respuesta4">
                  <option disabled value="">Respuesta</option>
                  <option value="Rojos">Rojos: neumáticos blandos</option>
                  <option value="Amarillos">Amarillos: neumáticos medios</option>
                  <option value="Blancos">Blancos: neumáticos duros</option>
                  <option value="Verdes">Verdes: neumáticos intermedios</option>
                  <option value="Azules">Azules: neumáticos de lluvia extrema</option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- Fin de pregunta 4 -->
              <!-- Pregunta 5 -->
              <p class="pregunta font-white font-regular mt-3">
                {{ form[4].pregunta5 }}
              </p>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[4].respuesta5">
                  <option disabled value="">Respuesta</option>
                  <option value="si">Sí</option>
                  <option value="no">No</option>
                </select>
                <p class="font-white font-regular score mt-3">25 Puntos</p>
              </div>
              <!-- Fin de pregunta 5 -->
              <div class="d-flex justify-center my-5">
                <button class="btn btn-lg"
                  :class="{ 'btn-launch-predicciones': isFormComplete, 'btn-launch-predicciones-disabled': !isFormComplete }"
                  :disabled="!isFormComplete">
                  Enviar respuestas
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 d-none d-md-block d-sm-none"></div>
    </div>
  </div>
</template>

<script src="../pilotos.js" />
<style src="../pilotos.scss" lang="scss" />
<style src="../../../../../App.scss" lang="scss" />
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "pilotosYuki",
  metaInfo: {
    title: "Predicciones | Mobil Predictor",
    meta: [
      {
        name: "title",
        content: "Predicciones | Mobil Predictor",
      },
      {
        name: "description",
        content:
          "¡Pronostica resultados de los pilotos de Oracle Red Bull Racing! Adivina en qué lugar quedarán Max Verstappen y Yuki Tsunoda. ¡Vence a los demás!",
      },
    ],
  },
  data() {
    return {
      questions: [],
      form: [
        {
          pregunta1: "¿En qué posición Yuki terminará la carrera?",
          respuesta1: "",
        },
        {
          pregunta2: "¿En qué posición Yuki terminará la Qualy?",
          respuesta2: "",
        },
        {
          pregunta3: "¿Número de paradas en boxes que Yuki hará en la carrera?",
          respuesta3: "",
        },
        {
          pregunta4: "¿Con qué neumáticos empezará la carrera?",
          respuesta4: "",
        },
        {
          pregunta5: "¿Será el piloto del día?",
          respuesta5: "",
        },
      ],
      showQuestions: true,
      isFormVisible: false,
      name_race: "",
      circuito: "",
      ciudad: "",
      gp: "",
    };
  },
  methods: {
    getGP(){
      const gp = localStorage.getItem("gp");
      if (gp && gp !== "0") {
        this.showQuestions = true;
        this.gp = gp;
      }else{
        this.showQuestions = false;
        this.gp = gp;
      }
    },
    submitFormQuestionsMobil() {
      const predictor = JSON.parse(localStorage.getItem("predictor"));
      const email = predictor.email;

      this.form.forEach((item, index) => {

        // Recuperar el objeto existente de localStorage
        let predicciones = localStorage.getItem("predicciones");

        // Parsear el objeto JSON recuperado, o inicializar un objeto vacío si no existe
        predicciones = predicciones ? JSON.parse(predicciones) : {};

        // Agregar el nuevo valor al objeto
        predicciones.Liam = this.form;

        // Guardar el objeto modificado de nuevo en localStorage
        localStorage.setItem("predicciones", JSON.stringify(predicciones));

        document.cookie =
          "respuestasLiam=liam; expires=" +
          new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toUTCString();

        setTimeout(() => {
          this.$router.push("/predicciones-mobil").catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
        }, 1000);
      });
    },
    alertaCambioPaginaCheco() {
      if (document.cookie.indexOf("respuestasLiam=liam") === -1) {
        Swal.fire({
          title: "¡Espera!",
          text:
            "Estás a punto de cambiar de página, envía tus respuestas antes de irte",
          icon: "info",
          confirmButtonText: "Ok",
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });
        setTimeout(() => {
          this.$router.push("/predicciones-pilotos-max");
        }, 1500);
      } else {
        this.$router.push("/predicciones-pilotos-max");
      }
    },
    alertaCambioPaginaMobil() {
      if (document.cookie.indexOf("respuestasLiam=liam") === -1) {
        Swal.fire({
          title: "¡Espera!",
          text:
            "Estás a punto de cambiar de página, envía tus respuestas antes de irte",
          icon: "info",
          confirmButtonText: "Ok",
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });
        setTimeout(() => {
          this.$router.push("/predicciones-mobil");
        }, 1500);
      } else {
        this.$router.push("/predicciones-mobil");
      }
    },
    obtenerCarrera(){
      axios
      .get('https://www.mobilpredictor.com.mx/datos/api/carreraActual')
      // .get('http://127.0.0.1:8000/api/carrera')
      // .get('https://api.mobilpredictor2025.vmtec.com.mx/api/carreraActual')
      .then((response) => {
        const raceData = response.data;
        // Para la vista
        this.name_race = JSON.stringify(raceData.carrera.name);
        this.circuito = JSON.stringify(raceData.carrera.circuit);
        this.ciudad = JSON.stringify(raceData.carrera.city);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  computed: {
    isFormComplete() {
      return this.form.every(item => {
        return Object.values(item).every(value => value !== "");
      });
    },
  },
  watch: {
    isFormComplete(newVal) {
      console.log('isFormComplete:', newVal);
    }
  },
  mounted() {
    // if (!localStorage.getItem("predictor")) {
    //   this.$router.push("/iniciar-sesion");
    // }

    if (localStorage.getItem("predicciones")) {
      const existingPredictions = JSON.parse(localStorage.getItem("predicciones"));
      if (existingPredictions && existingPredictions.Liam) {
        this.form = existingPredictions.Liam;
      }
    }

    const cookieExists = document.cookie.includes("PrediccionesEnviadas");
    this.isFormVisible = cookieExists;
    this.getGP();
    this.obtenerCarrera();
  },
};
</script>
