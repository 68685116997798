<template>
  <div class="w80 py-md-10">
    <div class="row text-center dosColumnas">
      <div class="col-lg-1"></div>
      <div class="col-lg-4 col-md-4 col-sm-12 d-none d-md-block">
        <div class="register-image-checo-max">
          <img class="MaxRegister" :src="require('../../../assets/2025/Register_max.png')" alt="" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="contenedorFormDktp mb-10">
          <h1 class="font-white text-center pt-3">
            Regístrate para participar
          </h1>
          <hr class="separador-h-home">
          <form action="" class="mt-5 w100" @submit.prevent="submitFormRegister">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="form-group mb-5">
                  <input type="text" class="form-control input24 font-regular" id="name" v-model="form.name"
                    placeholder="Nombre" />
                </div>
                <div class="form-group mb-5">
                  <input type="text" class="form-control input24 font-regular" id="first_name" v-model="form.first_name"
                    placeholder="Primer Apellido" />
                </div>
                <div class="form-group mb-5">
                  <input type="text" class="form-control input24 font-regular" id="last_name" v-model="form.last_name"
                    placeholder="Segundo Apellido" />
                </div>
                <p class="font-white font-regular text-center register-birthday-text">
                  Fecha de Nacimiento
                </p>
                <div class="form-group mb-5">
                  <div class="d-flex">
                    <input type="text" class="form-control input24 font-regular" v-model="form.birthdayDay" id="day"
                      placeholder="DD" maxlength="2" @input="focusNextInput('month', $event)" />
                    <input type="text" class="form-control input24 font-regular" v-model="form.birthdayMonth" id="month"
                      placeholder="MM" maxlength="2" @input="focusNextInput('year', $event)" />
                    <input type="text" class="form-control input24 font-regular" v-model="form.birthdayYear" id="year"
                      placeholder="AAAA" maxlength="4" />
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 separador-v-home">
                <div class="form-group mb-5">
                  <input type="text" class="form-control input24 font-regular" id="email" v-model="form.email"
                    placeholder="Correo" />
                </div>
                <div class="form-group mb-5">
                  <input type="text" class="form-control input24 font-regular" id="phone" v-model="form.phone"
                    placeholder="Teléfono" />
                </div>
                <div class="form-group mb-5">
                  <input :type="showPassword5 ? 'text' : 'password'" class="form-control input24 font-regular"
                    id="password" v-model="form.password" placeholder="Contraseña" />
                  <i class="fa fa-eye show-password-icon-register" @click="showPassword5 = !showPassword5"></i>
                </div>
                <div class="form-group mb-5 pt-10">
                  <!-- <p class="font-white font-regular">Acepto</p> -->
                  <div class="form-group container-tyc">
                    <div class="d-flex mb-2">
                      <input class="form-check-input checkbox-tyc" type="checkbox" id="terms" v-model="form.tyc" />
                      <label class="form-check-label font-white font-regular label-tyc" for="terms">
                        <a class="font-white font-regular lnk ms-3" href="/terminos-y-condiciones">
                          Términos y condiciones</a>
                      </label>
                    </div>
                  </div>
                  <div class="form-group container-tyc">
                    <div class="d-flex">
                      <input class="form-check-input checkbox-tyc" type="checkbox" id="avprv" />
                      <label class="form-check-label font-white font-regular label-tyc" for="terms">
                        <a class="font-white font-regular lnk ms-3"
                          href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing">
                          Aviso de privacidad</a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <button class="btn btn-primary btn-lg btn-launch registrar-button-registro">
                Registrar
              </button>
            </div>
          </form>
          <div class="d-flex justify-center mt-3 mb-5">
            <button class="font-white ya-tengo-cuenta-button-registro"
              @click="redirectWithTimeout('/comprueba-cuenta')">
              Ya tengo cuenta de Mobil Racing Club
            </button>
          </div>
          <p class="register-tyc font-white px-8 pb-5">
            Al iniciar sesión confirmas que estás listo para disfrutar de la
            carrera con nuestros Términos y Condiciones. Conoce más detalles
            <a href="/terminos-y-condiciones" target="_blank">aquí.</a>
          </p>
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
  </div>
</template>

<script src="./register.js" />
<style src="./register.scss" lang="scss" />
<style src="../../../App.scss" lang="scss" />
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "register",
  metaInfo: {
    title: "¡Regístrate y participa!| Mobil Predictor ",
    meta: [
      { name: "title", content: "¡Regístrate y participa!| Mobil Predictor " },
      {
        name: "description",
        content:
          "Verifica tu identidad en Mobil Predictor. Empieza a competir junto a miles de aficionados, gana premios y conviértete en el campéon. !Únete con un clic!",
      },
    ],
  },
  data() {
    return {
      form: {
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        birthdayDay: "",
        birthdayMonth: "",
        birthdayYear: "",
        tyc: "",
      },
      showPassword5: false,
      showPassword6: false,
    };
  },
  mounted() {
  if (localStorage.getItem("predictor") && document.cookie.includes("predictor")) {
    this.$router.push("/dashboard");
  }

  // Configurar Axios para enviar el token CSRF
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
  } else {
    console.error('CSRF token not found');
  }
},
  methods: {
    async submitFormRegister() {
      try {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        if (!this.form.tyc) {
          Swal.fire({
            icon: "warning",
            title: "¡Espera!",
            text: "Debes aceptar los términos y condiciones.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
          return;
        }
        const response = await axios.post(
          "https://www.mobilpredictor.com.mx/datos/api/register",
          // "http://127.0.0.1:8000/api/register",
          // "https://api.mobilpredictor2025.vmtec.com.mx/api/register",
          this.form
        );
        if (response.data.code == 201) {
          this.$router.push("/doble-auth");
          localStorage.setItem("predictor", JSON.stringify(response.data));
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);
          document.cookie = `predictor=${JSON.stringify(
            response.data.email
          )}; expires=${expirationDate.toUTCString()}; path=/`;
        }
      } catch (error) {
        if (error.response.data.code == "FailData") {
          Swal.fire({
            icon: "warning",
            title: "Hay un error en tu registro.",
            text:
              "Revisa que tus datos estén completos, que tu contraseña sea de mínimo 8 dígitos (con mayúsculas, minúsculas y números) y que tu teléfono sea de 10 dígitos.",
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }

        if (error.response.data.code == "UserExist") {
          Swal.fire({
            icon: "info",
            title: "¡Ups!",
            text: "Tu cuenta de correo ya se encuentra registrado.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }

        if (error.response.data.code == "EmailExistSF") {
          Swal.fire({
            icon: "info",
            title: "¡Ups!",
            text:
              "Encontramos que tu correo se encuentra registrado en Mobil ® Racing Club, te redireccionaremos para continuar con tu registro. ¡Gracias!",
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
            color: "#fff",
          });
          setTimeout(() => {
            this.$router.push("/comprueba-cuenta");
          }, 3000);
        }

        if (error.response.data.code == "InvalidDomain") {
          Swal.fire({
            icon: "warning",
            title: "¡Espera!",
            text:
              "Parece que tu correo no pertenece a un dominio conocido. Por favor, verifica tu correo y vuelve a intentar. ¡Gracias!",
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }
      }
    },
    focusNextInput(nextInput, event) {
      // Obtener el input actual y su valor
      const currentInput = event.target;
      const currentValue = currentInput.value;

      // Si el valor actual tiene la longitud máxima
      if (currentValue.length === currentInput.maxLength) {
        // Encontrar el siguiente input
        const nextInputEl = document.getElementById(nextInput);
        if (nextInputEl) {
          // Enfocar el siguiente input
          nextInputEl.focus();
        }
      }
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    },
  },
};
</script>
