<template>
  <div class="w80-crpwd my-10">
    <div class="row">
      <div class="col-lg-1 col-md-1 d-none d-md-block d-sm-none"></div>
      <div class="col-lg-2 col-md-3 d-none d-md-block d-sm-none">
        <div class="prediccionesMobil-container-texto">
          <h1 class="font-white font-regular prediccionesMobil-titulo">
            Predicciones
          </h1>
          <p class="font-white font-regular prediccionesMobil-texto">
            Es tu turno de pisar el acelerador. Añade tus avances y pronósticos para la próxima Gran Carrera.
          </p>
        </div>
        <!-- <p class="font-white text-home-brand prediccionesMobil-brand italica">
          Powered by Mobil<sup>®</sup> Racing Club
        </p> -->
      </div>
      <div class="col-lg-3 d-none d-lg-block d-sm-none text-center">
        <img class="rb21" src="../../../../../src/assets/2025/RB21.png" alt="RB21" />
      </div>
      <div class="col-lg-5 col-md-7 col-sm-12 col-xs-12">
        <div class="contenedor-Mobilpredicciones text-center py-5">
          <!-- <h1 class="font-white f40 mt-5">{{name_race}}</h1>
          <p class="font-white font-regular">{{circuito}}</p>
          <p class="font-white font-regular">{{ciudad}}</p> -->
          <div class="container-botones-predicciones">
            <div class="">
              <button @click="alertaCambioPaginaPilotos" class="piloto2 font-white font-regular font-regular italic">
                Max
              </button>
            </div>
            <div class="boton-secundario">
              <button @click="alertaCambioPaginaPilotos" class="piloto2 font-white font-regular font-regular italic">
                Yuki
              </button>
            </div>
            <div class="boton-secundario">
              <a href="/predicciones-pilotos-yuki" class="piloto font-white page-predicciones italic">Mobil</a>
            </div>
          </div>
          <div class="predicciones">
            <p class="font-white f40 mt-5" v-if="!showQuestions">
              Predicciones no disponibles, se abren el lunes antes de la carrera.
            </p>
            <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="showQuestions && !isFormVisible">
              <!-- Pregunta 1 -->
              <h3 class="pregunta font-white font-regular mt-3">
                {{ form[0].pregunta1 }}
              </h3>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[0].respuesta1">
                  <option disabled value="">Respuesta</option>
                  <option v-for="option in form[0].options" :key="option.id" :value="option.id">{{ option.text }}</option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- <div v-if="form[0].respuesta1 === 'D'" class="mt-3">
                <input type="text" v-model="form[0].otraRespuesta" placeholder="Especifica tu medio de transporte" class="input-predicciones" />
              </div> -->
              <!-- Fin de pregunta 1 -->
              <!-- Pregunta 2 -->
              <h3 class="pregunta font-white font-regular mt-3">
                {{ form[1].pregunta2 }}
              </h3>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[1].respuesta2">
                  <option disabled value="">Respuesta</option>
                  <option v-for="option in form[1].options" :key="option.id" :value="option.id">{{ option.text }}</option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- <div v-if="form[1].respuesta2 === 'D'" class="mt-3">
                <input type="text" v-model="form[1].otraRespuesta" placeholder="Especifica otro deporte de motor" class="input-predicciones" />
              </div> -->
              <!-- Fin de pregunta 2 -->
              <div class="d-flex justify-center my-5">
                <button class="btn btn-lg" :class="{ 'btn-launch-predicciones': isFormComplete, 'btn-launch-predicciones-disabled': !isFormComplete }" :disabled="!isFormComplete">
                  Enviar respuestas
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 d-none d-md-block d-sm-none"></div>
    </div>
  </div>
</template>
<script src="./mobil.js" />
<style src="../pilotos/pilotos.scss" lang="scss" />
<style src="./mobil.scss" lang="scss" />
<style src="../../../../App.scss" lang="scss" />
<!-- <style src="../predicciones/predicciones.scss" lang="scss" />  -->
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "mobil",
  metaInfo: {
    title: "Predicciones | Mobil Predictor",
    meta: [
      {
        name: "title",
        content: "Predicciones | Mobil Predictor",
      },
      {
        name: "description",
        content:
          "¡Pronostica resultados de los pilotos de Oracle Red Bull Racing! Adivina en qué lugar quedarán Max Verstappen y Yuki Tsunoda. ¡Vence a los demás!",
      },
    ],
  },
  data() {
    return {
      questions: [],
      form: [
        {
          pregunta1: "¿Qué equipos de motorsports patrocina Mobil™?",
          respuesta1: "",
          options: [
            { id: "A", text: "A) Oracle Red Bull Racing, Porsche Formula E Team, y Red Bull KTM Factory Racing" },
            { id: "B", text: "B) Ferrari F1, Yamaha Factory Racing y Team Honda Racing (MotoGP)" },
            { id: "C", text: "C) Aston Martin Red Bull Racing, Suzuki MotoGP y Renault F1 Team" },
          ],
          otraRespuesta: "", // Campo para la respuesta "Otro"
        },
        {
          pregunta2: "¿Cómo realizas un check-in en la Mobil™ App?",
          respuesta2: "",
          options: [
            { id: "A", text: "A) Comprando productos Mobil™"},
            { id: "B", text: "B) Cargando combustible Mobil™"},
            { id: "C", text: "C) Escaneando el código de una botella de lubricante Mobil™"},
            // { id: "D", text: "D) Otro" },
          ],
          otraRespuesta: "", // Campo para la respuesta "Otro"
        },
      ],
      showQuestions: true,
      isFormVisible: false,
      name_race: "",
      circuito: "",
      ciudad: "",
      gp: "",
    };
  },
  methods: {
    getGP(){
      const gp = localStorage.getItem("gp");
      if (gp && gp !== "0") {
        this.showQuestions = true;
        this.gp = gp;
      }else{
        this.showQuestions = false;
        this.gp = gp;
      }
    },
    submitFormQuestionsMobil() {
      const predictor = JSON.parse(localStorage.getItem("predictor"));
      const email = predictor.email;

      // Recuperar el objeto existente de localStorage
      let predicciones = localStorage.getItem("predicciones");
      // Parsear el objeto JSON recuperado, o inicializar un objeto vacío si no existe
      predicciones = predicciones ? JSON.parse(predicciones) : {};
      // Agregar el nuevo valor al objeto
      predicciones.Mobil = {
        gp: this.gp,
        ...this.form,
        email: email
      };
      // Guardar el objeto modificado de nuevo en localStorage
      localStorage.setItem("predicciones", JSON.stringify(predicciones));

      let prediccionesFinales = JSON.parse(localStorage.getItem("predicciones"));

      // Envía los datos a la API
      axios
        .post(
          "https://www.mobilpredictor.com.mx/datos/api/predicciones",
          // "http://127.0.0.1:8000/api/predicciones",
          // "https://api.mobilpredictor2025.vmtec.com.mx/api/predicciones",
          prediccionesFinales
        )
        .then((response) => {
          // console.log(response);

          // Borrar el objeto predicciones del LocalsStorage
          localStorage.removeItem("predicciones");

          document.cookie =
            "PrediccionesEnviadas=true; expires=" +
            new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toUTCString();

          setTimeout(() => {
            this.$router.push("/exito").catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "¡Espera!",
            text:
              "Envíanos tus respuestas completas para poder participar en el predictor y conseguir más puntos.",
            icon: "info",
            confirmButtonText: "Volver a intentar",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        });
    },
    alertaCambioPaginaPilotos() {
      if (document.cookie.indexOf("respuestasMobil=mobil") === -1) {
        Swal.fire({
          title: "¡Espera!",
          text:
            "Recuerda que debes volver aquí, para completar el registro de tus predicciones.",
          icon: "info",
          confirmButtonText: "Ok",
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });

        setTimeout(() => {
          this.$router.push("/predicciones-pilotos-max");
        }, 1500);
      } else {
        this.$router.push("/predicciones-pilotos-max");
      }
    },
    obtenerCarrera(){
      axios
      .get('https://www.mobilpredictor.com.mx/datos/api/carreraActual')
      // .get('http://127.0.0.1:8000/api/carrera')
      // .get('https://api.mobilpredictor2025.vmtec.com.mx/api/carreraActual')
      .then((response) => {
        const raceData = response.data;
        // Para la vista
        this.name_race = JSON.stringify(raceData.carrera.name);
        this.circuito = JSON.stringify(raceData.carrera.circuit);
        this.ciudad = JSON.stringify(raceData.carrera.city);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  computed: {
    isFormComplete() {
      return this.form.every(item => {
        // Verificar si la respuesta es "Otro" y si el campo de texto está lleno
        if ((item.respuesta1 === 'C' && !item.otraRespuesta) || (item.respuesta2 === 'D' && !item.otraRespuesta)) {
          return false;
        }
        // Verificar que la respuesta principal no esté vacía
        if (!item.respuesta1 && !item.respuesta2) {
          return false;
        }
        return true;
      });
    },
  },
  watch: {
    isFormComplete(newVal) {
      console.log('isFormComplete:', newVal);
    }
  },
  mounted() {
    if (!localStorage.getItem('predictor')) {
      this.$router.push('/iniciar-sesion');
    }

    const cookieExists = document.cookie.includes("PrediccionesEnviadas");
    this.isFormVisible = cookieExists;
    this.getGP();
    this.obtenerCarrera();
  },
};
</script>
