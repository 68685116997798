<template>
  <div class="w80-login my-20">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="px-5 mx-10">
          <h1 class="font-white font-regular text-left ms-10 mb-2 italic">
            Mobil® Predictor
          </h1>
          <!-- <hr class="separador-preguntas-frecuentes"> -->
          <p class="font-white font-regular text-left ms-10 mt-2 pf-text">Conviértete en el maestro de las predicciones de la
            Gran
            Carrera.
            ¿Listo para entrar en la pista? Prepárate
            para una
            experiencia llena de adrenalina.</p>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <img class="ChecoCascopFrecuentes" src="../../../assets/ChecoCasco.png" alt="Max">
      </div> -->
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="mb-5 text-center px-5">
          <h1 class="font-white font-regular text-center mb-3 italic">
            Preguntas Frecuentes
          </h1>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <v-expansion-panels class="paneles-preguntas-frecuentes mb-3 mx-auto font-regular" accordion>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Qué es Mobil Predictor?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Mobil® Predictor es una plataforma para hacer predicciones sobre los resultados de las carreras de
                      Oracle Red Bull Racing durante la temporada 2024, con la oportunidad de ganar premios al acumular
                      puntos según los resultados de las carreras.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Cómo jugar?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Para participar se deben hacer las predicciones y contestar la preguntas indicadas dentro del
                      plazo
                      definido.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Necesito registrarme?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Es necesario registrar un usuario y contraseña y entrar con las credenciales definidas por el
                      usuario
                      para acceder a las preguntas.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Es gratis?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Sí, Mobil® Predictor es una plataforma de participación gratuita.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Puedo jugar aunque la temporada ya haya empezado?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Así es, cada carrera puedes ganar puntos y calificar en la tabla de posiciones sin importar la
                      Gran
                      Carrera en la que comenzaste a participar.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Cuál es la fecha límite para hacer mis predicciones?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Las predicciones se abrirán el lunes (12:00 am) de la semana que haya Gran Carrera y se cerrarán
                      el
                      sábado antes de la clasificación a excepción de los circuitos de Austria y Estados Unidos (Texas).

                      Las predicciones de las carreras que cuenten con “Sprint Race” tendrán sus propios horarios
                      dependiendo del lugar en donde sea la carrera:

                      Austria: las predicciones inician lunes 12:00 am y cerraran el viernes a las 8:59 am (la
                      clasificación
                      es el viernes a las 9 am)

                      Estados Unidos: las predicciones inician lunes 12:00 am y cerraran el viernes a las 2:59 pm (la
                      clasificación es el viernes a las 3:00 pm)
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Cuáles son los premios?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      En Mobil® Racing Club y Mobil® Predictor premiamos a nuestros campeones con merch exclusiva,
                      colecciones limitadas, mercancía oficial de Oracle Red Bull Racing y la oportunidad de asistir a
                      nuestros eventos más esperados del año con la presencia de invitados especiales.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 separador-vertical-preguntas-frecuentes">
              <v-expansion-panels class="paneles-preguntas-frecuentes mb-3 mx-auto font-regular" accordion>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Está disponible en mi región?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Mobil® Predictor y sus recompensas estarán disponibles únicamente para participantes que residan
                      en la
                      república mexicana durante la temporada 2024.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Qué tipo de predicciones puedo hacer y cómo me benefician?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Al entrar en la pestaña de Predicciones podrás encontrar todas las preguntas con las cuales puedes
                      ganar puntos por tus predicciones sobre el desempeño y resultados de los pilotos de Oracle Red
                      Bull
                      Racing y preguntas especiales sobre Mobil®.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Cuándo y dónde veré mi puntuación?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Puedes acceder a tu puntuación en la pestaña de Tabla de Posiciones. Verás tus puntos reflejados
                      una
                      vez que hayas completado tus primeras predicciones.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Cómo puedo alcanzar la cima de la tabla de clasificación?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Cada pregunta tiene un valor en puntos, por lo que al tener la mayor cantidad de predicciones
                      acertadas y preguntas Mobil® correctamente respondidas, tendrás más posibilidades de cada carrera
                      ir
                      subiendo tu puntuación hasta alcanzar las primeras posiciones.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Qué puedo hacer si tengo problemas para acceder a mi cuenta?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      En la página de Inicio selecciona la opción Restablecer contraseña y te ayudaremos a recuperar tu
                      cuenta al correo registrado en Mobil® Racing Club.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      No encuentro la respuesta a mi pregunta sobre Mobil Predictor. ¿Qué debo hacer?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Escríbenos a info@mobilpredictor.com.mx para obtener más información. Es importante que también
                      leas
                      los Términos y Condiciones para aclarar tus dudas a profundidad.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="panel-pregunta-frecuente mb-2">
                  <v-expansion-panel-header class="text-center">
                    <h3 class="titulo-pregunta-frecuente">
                      ¿Pertenece a Oracle Red Bull Racing?
                    </h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-center">
                    <p class="texto-pregunta-frecuente">
                      Mobil® Predictor no está asociado directamente con la escudería ORBR. El dominio pertenece a
                      Mobil®
                      México y está asociado a Mobil® Racing Club, el club de fans oficial de Mobil®.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./preguntasFrecuentes.scss" lang="scss" />
<script src="../iniciarSesion/iniciarSesion.js" />
<style src="../iniciarSesion/iniciarSesion.scss" lang="scss" />

<script>
export default {
  name: 'PreguntasFrecuentes',
  metaInfo: {
    title: 'FAQ| Mobil Predictor ',
    meta: [
      { name: 'title', content: 'FAQ| Mobil Predictor ' },
      { name: 'description', content: 'Resuelve dudas sobre pronósticos, premios y registro. Encuetra lo que debes saber para ganar premios por apoyar a Max Verstappen y RBR en la temporada 2025.' }
    ]
  }
}
</script>
