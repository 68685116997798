import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtm from 'vue-gtm'
// import HomeView from '../views/homeView/homeView.vue'
// import AboutView from '../views/aboutView/aboutView.vue'
// import GalleryView from '../views/galleryView/galleryView.vue'
// import ParticipationView from '../views/participationView/participationView.vue'
// import SendView from '../views/sendView/sendView.vue'
// import previewParticipation from '../views/previewParticipation/previewParticipation.vue'
// import RecomendationsView from '../views/recomendationsView/recomendationsView.vue'
// import ViewParticipation from '../views/viewParticipation/viewParticipation.vue'
// import myParticipations from '../views/myParticipation/myParticipation.vue'
// import avisoPrivacidad from '../views/avisoPrivacidad/avisoPrivacidad.vue'
// import terminosCondiciones from '../views/terminosCondiciones/terminosCondiciones.vue'
// Nuevas pantallas
import home from '../views/mobil2024/home/home.vue'
import register from '../views/mobil2024/register/register.vue'
import password from '../views/mobil2024/crearPassword/crearPassword.vue'
import iniciarSesion from '../views/mobil2024/iniciarSesion/iniciarSesion.vue'
import dobleAuth from '../views/mobil2024/dobleAuth/dobleAuth.vue'
import dashboard from '../views/mobil2024/dashboard/dashboard.vue'
// import predicciones from '../views/mobil2024/predictor/predicciones/predicciones.vue'
import mobil from '../views/mobil2024/predictor/mobil/mobil.vue'
import pilotos from '../views/mobil2024/predictor/pilotos/pilotos.vue'
import pilotosMax from '../views/mobil2024/predictor/pilotos/Max/pilotosMax.vue'
import resetPassword from '../views/mobil2024/resetPassword/resetPassword.vue'
import buscarCuenta from '../views/mobil2024/buscarCuenta/buscarCuenta.vue'
import actualizarPassword from '../views/mobil2024/actualizarPassword/actualizarPassword.vue'
import preguntasFrecuentes from '../views/mobil2024/preguntasFrecuentes/preguntasFrecuentes.vue'
import tyc from '../views/mobil2024/tyc/tyc.vue'
import premios from '../views/mobil2024/premios/premios.vue'
import prediccionesEnviadas from '../views/mobil2024/prediccionesEnviadas/prediccionesEnviadas.vue'
import VueMeta from 'vue-meta'
// import { component } from 'vue/types/umd.js'

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/Home',
  //   name: 'Home',
  //   component: HomeView,
  //   meta: { gtm: 'Homepage' }
  // },
  // {
  //   path: '/acerca/:url',
  //   name: 'About',
  //   component: AboutView,
  //   meta: { gtm: 'Acerca / Desde Scan' }
  // },
  // {
  //   path: '/acerca',
  //   name: 'About2',
  //   component: AboutView,
  //   meta: { gtm: 'Acerca / Menú' }
  // },
  // {
  //   path: '/envia',
  //   name: 'Send',
  //   component: ParticipationView,
  //   meta: { gtm: 'Grabación' }
  // },
  // {
  //   path: '/porras',
  //   name: 'Participations',
  //   component: GalleryView,
  //   meta: { gtm: 'Contenido USG' }
  // },
  // {
  //   path: '/porras/todos',
  //   name: 'Participations2',
  //   component: GalleryView,
  //   meta: { gtm: 'Participaciones' }
  // },
  // {
  //   path: '/escanear',
  //   name: 'VR',
  //   component: SendView,
  //   meta: { gtm: 'Pantalla Escaneo' }
  // },
  // {
  //   path: '/enviado',
  //   name: 'Preview',
  //   component: previewParticipation,
  //   meta: { gtm: 'Grabación Terminada / Preview' }
  // },
  // {
  //   path: '/sugerencias',
  //   name: 'Recomendations',
  //   component: RecomendationsView,
  //   meta: { gtm: 'Recomendaciones' }
  // },
  // {
  //   path: '/participacion',
  //   name: 'Parti',
  //   component: HomeView,
  //   meta: { gtm: 'Participación Individual' }
  // },
  // {
  //   path: '/participacion/:uuid',
  //   name: 'Participation',
  //   component: ViewParticipation,
  //   meta: { gtm: 'Participación Específica' }
  // },
  // {
  //   path: '*',
  //   name: 'NotFoud',
  //   component: HomeView
  // },
  // {
  //   path: '/participaciones',
  //   name: 'myParticipations',
  //   component: myParticipations,
  //   meta: { gtm: 'Mis Participaciones' }
  // },
  // {
  //   path: '/avisodeprivacidad',
  //   name: 'avisoPrivacidad',
  //   component: avisoPrivacidad,
  //   meta: { gtm: 'Aviso de Privacidad' }
  // },
  // {
  //   path: '/terminosycondiciones',
  //   name: 'terminosCondiciones',
  //   component: terminosCondiciones,
  //   meta: { gtm: 'Términos y Condiciones' }
  // },
  // Nuevas pantallas 2024
  {
    path: '/',
    name: 'Home',
    component: home,
    meta: { gtm: 'Home' }
  },
  {
    path: '/iniciar-sesion',
    name: 'Iniciar sesion',
    component: iniciarSesion,
    meta: { gtm: 'Iniciar sesion' }
  },
  {
    path: '/registro',
    name: 'Registro',
    component: register,
    meta: { gtm: 'Registro' }
  },
  {
    path: '/doble-auth',
    name: 'F2A',
    component: dobleAuth,
    meta: { gtm: 'F2A' }
  },
  {
    path: '/comprueba-cuenta',
    name: 'Buscar cuenta',
    component: buscarCuenta,
    meta: { gtm: 'Buscar cuenta' }
  },
  {
    path: '/crear-contrasena/:correo',
    name: 'Password',
    component: password,
    meta: { gtm: 'Crear contraseña' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: dashboard,
    meta: { gtm: 'Puntuaciones' }
  },
  {
    path: '/predicciones-pilotos-max',
    name: 'Predicciones Max',
    component: pilotos,
    meta: { gtm: 'Predicciones Pilotos Max' }
  },
  {
    path: '/predicciones-pilotos-yuki',
    name: 'Predicciones Yuki',
    component: pilotosMax,
    meta: { gtm: 'Predicciones Pilotos Yuki' }
  },
  {
    path: '/predicciones-mobil',
    name: 'Predicciones Mobil',
    component: mobil,
    meta: { gtm: 'Predicciones Mobil' }
  },
  {
    path: '/olvide-contrasena',
    name: 'Olvide mi Password',
    component: resetPassword,
    meta: { gtm: 'Olvide mi contraseña' }
  },
  {
    path: '/cambiar-contrasena/:correo',
    name: 'Cambiar contraseña',
    component: actualizarPassword,
    meta: { gtm: 'Cambiar contraseña' }
  },
  {
    path: '/preguntas-frecuentes',
    name: 'FAQ',
    component: preguntasFrecuentes,
    meta: { gtm: 'FAQ' }
  },
  {
    path: '/terminos-y-condiciones',
    name: 'TYC',
    component: tyc,
    meta: { gtm: 'Terminos y condiciones' }
  },
  {
    path: '/premios',
    name: 'Premios',
    component: premios,
    meta: { gtm: 'Premios' }
  },
  {
    path: '/exito',
    name: 'Exito',
    component: prediccionesEnviadas,
    meta: { gtm: 'Exito' }
  }
  // {
  //   path: '/predicciones',
  //   name: 'Predicciones',
  //   component: predicciones,
  //   meta: { gtm: 'Predictor' }
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.use(VueGtm, { id: 'GTM-52CFLLRW', defer: false, enabled: true, vueRouter: router, loadScript: false })

export default router
