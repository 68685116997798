<template>
  <div class="w80-premios px-md-10 my-20">
    <v-row class="mx-auto px-md-10">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="container-premios-texto">
          <!-- <h1 class="premios-titulo font-white font-bold">¡Participa por increibles premios!</h1> -->
            <p class="premios-texto font-white font-regular">Algo grande está por llegar... Muy pronto descubrirás los increíbles premios que hemos preparado para ti esta temporada.</p>
            <p class="premios-texto font-white font-regular">Una pista: piensa en accesos a eventos secretos, merch exclusiva y la oportunidad de conocer a los más veloces en persona. 🏁</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="container-imagen-premios">
          <img class="PremiosMobilPredictor" src="../../../../src/assets/2025/premios.png" alt="PremiosMobilPredictor">
        </div>
      </div>
    </v-row>
  </div>
</template>

<style src="./premios.scss" lang="scss"></style>
<script>
export default {
  name: 'premios',
  metaInfo: {
    title: 'Premios| Mobil Predictor',
    meta: [
      { name: 'title', content: 'Premios| Mobil Predictor' },
      { name: 'description', content: '¡Gana por ver la máxima justa del automovilismo! Gana recompensas exclusivas por acertar en tus pronósticos. ¡Demuestra que tan experto eres en Mobil Predictor!' }
    ]
  }
}
</script>
