<template>
  <div class="w100 my-20 px-sm-10">
    <div class="row text-center">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="container-left-dashboard">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <h1 class="font-white font-bold text-left">Mis resultados</h1>
              <hr class="separador-puntuaciones mb-3">
            </div>
            <div class="col-lg-6 col-md-6 d-sm-none dnone"></div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div v-for="(data, index) in filteredData" :key="index" class="row dashboard-score">
                <div class="col-6">
                  <p class="font-white font-regular text-score text-left mb-3">Mi posición</p>
                  <p class="scoreboard font-regular">{{ data.ranking }}</p>
                </div>
                <!-- <div class="col-5">
                <p class="font-white font-regular text-score mt-2 mb-3">Usuario</p>
                <p class="scoreboard font-regular">{{ data.name }}</p>
              </div> -->
                <div class="col-6">
                  <p class="font-white font-regular text-score text-left mb-3">Puntos</p>
                  <p class="scoreboard font-regular">{{ data.score }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="mtmb-mobile">
                <button class="btn btn-primary btn-lg btn-launch-puntuaciones font-white"
                  @click="redirectWithTimeout('/predicciones-pilotos-max')">Predecir resultados</button>
              </div>
            </div>
          </div>
          <!-- Tabla de mejores 10 -->
          <h1 class="font-white text-left mb-3">Top 10</h1>
          <div class="container-top10">
            <div class="row dashboard-table-content">
              <div class="col-4 p-none">
                <p class="font-white font-regular">Posición</p>
              </div>
              <div class="col-4 p-none">
                <p class="font-white font-regular">Usuario</p>
              </div>
              <div class="col-4 p-none">
                <p class="font-white font-regular">Puntos</p>
              </div>
            </div>
            <div v-for="(data, index) in rankingData" :key="index" class="row dashboard-table-content">
              <div class="col-4 p-none">
                <p class="font-white score-boards ">{{ data.ranking }}</p>
              </div>
              <div class="col-4 p-none">
                <p class="font-white score-boards ">{{ data.name + ' ' + data.first_name.charAt(0) + '.' }}</p>
              </div>
              <div class="col-4 p-none">
                <p class="font-white score-boards ">{{ data.score }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 text-center">
        <img class="dashboard-pilotos" src="../../../assets/2025/dashboard_pilotos_rb.png" alt="PilotosRB">
      </div>
    </div>
  </div>
</template>

<script src="./dashboard.js" />
<style src="./dashboard.scss" lang="scss" />
<script>
import axios from "axios";

export default {
  name: 'dashboard',
  metaInfo: {
    title: 'Dashboard | Mobil Predictor ',
    meta: [
      { name: 'title', content: 'Dashboard | Mobil Predictor ' },
      { name: 'description', content: 'Controla tus estadísticas, posición en el ranking y ajusta tus pronósticos. Optimiza tu estrategia. ¡Escala posiciones junto a Max Verstappen y RBR!' }
    ]
  },
  data() {
    return {
      rankingData: [],
      filteredData: [],
      resultadosCarrera: {}
    };
  },
  mounted() {
    this.obtenerCarrera();
    this.fetchData();
    // if (!localStorage.getItem('predictor')) {
    //   this.$router.push('/iniciar-sesion');
    // }
    this.puntosPorCarrera();
  },
  methods: {
    fetchData() {
      axios
        .get('https://www.mobilpredictor.com.mx/datos/api/ranking')
        // .get('http://127.0.0.1:8000/api/ranking')
        // .get('https://api.mobilpredictor2025.vmtec.com.mx/api/ranking')
        .then((response) => {
          // Solo muestra los 10 primeros
          this.rankingData = response.data.filter(data => data.ranking !== null).sort((a, b) => a.ranking - b.ranking).slice(0, 10);

          const predictor = JSON.parse(localStorage.getItem('predictor'));
          const user = predictor.email;

          // Mostrar el puntaje de cada usuario
          this.filteredData = response.data.filter(data => data.email === user);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    puntosPorCarrera() {
      const predictor = JSON.parse(localStorage.getItem('predictor'));
      const email = predictor.email;

      axios.post('https://www.mobilpredictor.com.mx/datos/api/puntuacionesCarrera', { email })
      // axios.post('http://127.0.0.1:8000/api/puntuacionesCarrera', { email })
      // axios.post('https://api.mobilpredictor2025.vmtec.com.mx/api/puntuacionesCarrera', { email })
        .then((response) => {
          this.resultadosCarrera = response.data;
          console.log(this.resultadosCarrera);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    },
    obtenerCarrera(){
      axios
      .get('https://www.mobilpredictor.com.mx/datos/api/carreraActual')
      // .get('http://127.0.0.1:8000/api/carrera')
      // .get('https://api.mobilpredictor2025.vmtec.com.mx/api/carreraActual')
      .then((response) => {
        const raceData = response.data;
        if(raceData.carrera === '0'){
          localStorage.setItem('gp', 0);
        }else{
          localStorage.setItem('gp', JSON.stringify(raceData.carrera));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
};
</script>
