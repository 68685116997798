<template>
  <div class="w100 px-5 px-sm-10 my-20">
    <div class="row mx-auto px-md-10">
      <div class="col-lg-4 col-md-4 d-none d-md-block d-sm-none"></div>
      <div class="col-lg-4 col-md-4 col-sm-8 col-xs-12">
        <div class="contenedor-mensaje">
          <h1 class="font-white f40 mt-5">{{name_race}}</h1>
          <p class="font-white font-regular">{{circuito}}</p>
          <p class="font-white font-regular">{{ciudad}}</p>
          <p class="font-white font-regular exito-titulo italic">Tus Predicciones han sido enviadas.</p>
          <p class="font-white font-regular exito-texto">¡Mucha Suerte!</p>
          <!-- <button class="font-regular exito-boton" @click="volverAPredicciones">Regresar</button> -->
        </div>
        <!-- <div class="px-md-10 text-center mt-10">
          <p class="font-white text-exito-brand italica">Powered by Mobil<sup>®</sup> Racing Club</p>
        </div> -->
      </div>
      <div class="col-lg-4 col-md-4 d-none d-md-block d-sm-none"></div>
    </div>
  </div>
</template>

<!-- <script src="./exito.js" /> -->
<style src="./prediccionesEnviadas.scss" lang="scss" />
<script>
import axios from 'axios'
export default {
  data() {
    return {
      name_race: '',
      circuito: '',
      ciudad: ''
    }
  },
  methods: {
    volverAPredicciones() {
      this.$router.push('/predicciones-pilotos-max')
    },
    obtenerCarrera() {
      axios
        .get('https://www.mobilpredictor.com.mx/datos/api/carreraActual')
        // .get('http://127.0.0.1:8000/api/carrera')
        // .get('https://api.mobilpredictor2025.vmtec.com.mx/api/carreraActual')
        .then((response) => {
          const raceData = response.data
          // Para la vista
          this.name_race = JSON.stringify(raceData.carrera.name)
          this.circuito = JSON.stringify(raceData.carrera.circuit)
          this.ciudad = JSON.stringify(raceData.carrera.city)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted() {
    window.fbq('track', 'CompleteRegistration')
    this.obtenerCarrera()
  }
}
</script>
